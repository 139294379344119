<template>

	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>套餐管理</p>
			<i>/</i>
			<p>套餐列表</p>
		</nav>

		<div class="head_search_wrap">

			<div class="search_one_line">
				<div class="left">
					<el-form label-width="120px" @submit.native.prevent>
						<el-form-item label="套餐名称：">
							<el-input class="input_medi" v-model="keyword" @keyup.enter.native="searchFn" clearable placeholder="请输入套餐名称"></el-input>
						</el-form-item>
						<el-form-item label="状态：">
							<el-select v-model="type" placeholder="全部状态">
								<el-option label="上架" value="1"></el-option>
								<el-option label="下架" value="0"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>

		</div>

		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>套餐列表</p>
				</div>
				<div class="right">
					<span class="span2" @click="changePrice">批量改价</span>
					<span class="span1" @click="addFn">新增套餐</span>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="combo_name" label="套餐名称" align="left"></el-table-column>
				<el-table-column prop="combo_sn" label="套餐编码" align="left"></el-table-column>
				<el-table-column prop="" label="价格" align="center">
					<template slot-scope="scope">
						<div class="operation">
							<span>￥{{scope.row.price}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="sort" label="排序" align="center"></el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.status == '1'">
							<p class="is_sale_1">已上架</p>
						</div>
						<div v-else>
							<p class="is_sale_0">已下架</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="sales_volume" label="销量" align="center"></el-table-column>
				<el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="modifyFn(scope.row)">修改</span>
							<span v-if="scope.row.status == '1'" @click="editstatusFn(scope.row)">下架</span>
							<span v-else @click="editstatusFn(scope.row)">上架</span>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>

		</div>
		
	</div>




</template>

<script>
	export default {
		name: 'packageList',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				type: '', // 状态
			}
		},
		components: {},
		created() {
			// 获取套餐列表
			this.getPackList();
		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.keyword = '';
				this.type = '';
				
				this.getPackList();
			},

			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getPackList();
			},
			
			// 获取套餐列表
			getPackList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}combo/list`,
					data: {
						page: this.currentPage1,
						combo_name: this.keyword,
						status: this.type,
					}
				}).then( res => {
					if(res.code == '0'){
						this.total = res.count;
						this.listData = res.data;
					}
				})
			},

			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getPackList();
			},

			// 修改
			modifyFn(row) {
				console.log(row)
				this.$router.push({
					path: '/packageEdit',
					query:{
						id: row.id,
					}
				})
			},

			// 新增
			addFn() {
				this.$router.push('/packageEdit');
			},
			
			// 上下架
			editstatusFn(row){
				this.$confirm('继续此操作吗。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					
					let status = '';
					if(row.status == '1'){
						status = '0'
					}
					else{
						status = '1'
					}
					this.$sendHttp({
						method: 'POST',
						url: `${process.env.VUE_APP_SAAS}combo/editstatus`,
						data: {
							id: row.id,
							status: status,
						}
					}).then( res => {
						if(res.code == '0'){
							this.$message({
								message: '操作成功',
								type: 'success'
							});
							row.status = !row.status;
						}
					})
					
				}).catch(() => {});
				
			},
			
			// 批量改价
			changePrice(){
				this.$router.push('/batchChangePrice')
			},
			
			
		}
	}
</script>
<style scoped lang="less">

</style>
